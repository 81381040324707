import Collapsible from "react-collapsible";
import "./Filters.css";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Categories } from "../Map";
import { useLocation } from "react-router";

const Filters = ({
  locationHooks,
  categories,
  tags,
  selectCategories,
  selectedCategories,
  selectedTags,
  selectTags
}) => {
  const type = new URLSearchParams(useLocation().search).get("type");
  const [open, setOpen] = useState(true);
  const triggerSiblingExample = () => (
    <span className="open-close-icon" onClick={() => setOpen(!open)}>
      <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
    </span>
  );

  return (
    <Collapsible
      trigger="Filtri"
      containerElementProps={{ id: "filters" }}
      triggerSibling={triggerSiblingExample}
      handleTriggerClick={() => setOpen(!open)}
      open={open}
    >
      <p className="categories">Categorie</p>
      <div className="columns is-multiline">
        {Categories[type].map((category, i) => {
          return (
            <div className="column is-half" key={i}>
              <div
                className={`categories-logo categories-logo--${category.replace(/ /g,"-")} ${
                  selectedCategories.includes(category)
                    ? `categories-logo--active--${category.replace(/ /g,"-")}`
                    : ""
                }`}
                onClick={() => selectCategories(category)}
              >
                <img src={selectedCategories.includes(category) ? `assets/images/${category.replace(/-/g," ")}-ON.png` : `assets/images/${category.replace(/-/g," ")}-OFF.png`} />
              </div>
            </div>
          );
        })}
      </div>
      {tags.length > 0 && <><p className="certificates">Certificazioni</p>
      <>
        {tags.map((tag, i) => {
          return (
            <div className="certificates-line" key={i}>
              <p className={`certificates-name ${selectedTags.includes(tag) ? 'certificates-name--active' : ''}`}
               onClick={() => selectTags(tag)}
              >
                {tag}
              </p>
            </div>
          );
        })}
      </>
      </>
}
      {/* <div className="column is-half">
            <div className="categories-logo categories-logo--background">
                <img src='assets/platinum.png' />
            </div>
        </div>
        <div className="column is-half"><div className="categories-logo">
                <img src='assets/platinum.png' />
            </div></div>
            <div className="column is-half"><div className="categories-logo">
                <img src='assets/platinum.png' />
            </div></div>
            
      </div> */}
    </Collapsible>
  );
};

export default Filters;
