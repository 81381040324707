import "./Footer.css";
import { SocialIcon } from "react-social-icons";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-socials">
          <a href="https://www.facebook.com/KyoceraIta">
            <img src={"assets/images/ICO_Footer_Facebook.jpg"} />
          </a>
          <a href="https://www.linkedin.com/company/kyocera-document-solutions-italia/">
            <img
              src={"assets/images/ICO_Footer_Linkedin.jpg"}
              style={{ marginRight: "10px" }}
            />
          </a>
        </div>
        <div className="footer-links">
          <div className="columns">
            <div className="column is-8">
              <div className="">
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/footer/privacy-and-cookie-centre.html"
                  >
                    Privacy e centro cookie
                  </a>
                </div>
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/footer/richiesta-accesso-dati.html"
                  >
                    Richiesta di accesso ai dati del soggetto
                  </a>
                </div>
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/footer/condizioni-utilizzo.html"
                  >
                    Condizioni d'uso
                  </a>
                </div>
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/footer/note-legali.html"
                  >
                    Note legali
                  </a>
                </div>
              </div>
              <div>
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/preferenze-gdpr.html"
                  >
                    Manage Your Cookies
                  </a>
                </div>
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/chi-siamo/contatti/sala-stampa.html"
                  >
                    Press
                  </a>
                </div>
                <div className="footer-links">
                  <a
                    className="footer-link"
                    href="https://www.kyoceradocumentsolutions.it/it/footer/codice-etico.html"
                  >
                    Codice Etico
                  </a>
                </div>
              </div>
            </div>
            <div className="column is-4">
              <p className="footer-copyright">
                &copy; 2021 KYOCERA Document Solutions Italia S.p.A.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
