import React, { useEffect, useState } from "react";
import "./Autocomplete.css";
import conf from "../config.json";
import useScript from "../hooks/useScript";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

const Autocomplete = ({
  setLocationHooks,
  map,
  keyword,
  setKeyword,
  setGeoLocate,
  geoLocate,
}) => {
  const [results, setResults] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [autocompleteService, setAutocompleteService] = useState(null);
  const woosmapLoaded = useScript(conf.woosmapLocalitiesUrl);

  useEffect(() => {
    if (woosmapLoaded) {
      const autocompleteService =
        new window.woosmap.localities.AutocompleteService(
          conf.woosmapLoadOptions.publicKey
        );
      setAutocompleteService(autocompleteService);
    }
  }, [woosmapLoaded]);

  const updateText = (text) => {
    setKeyword(text);
    setLocationHooks(null);
    if (!text) {
      setNotFound(false);
      setResults([]);
    }
  };
  const updateKeyword = (text) => {
    updateText(text);
    onSearch(text);
  };

  const searchNearbyStores = (text, id) => {
    updateText(text);
    setResults([]);

    //updateLocation on click

    autocompleteService.getDetails(id, (localityDetails) => {
      setLocationHooks(localityDetails.geometry.location);
      if (geoLocate) {
        setGeoLocate(!geoLocate);
      }
      new window.woosmap.maps.utils.centerAndZoom(
        map,
        { ...localityDetails.geometry.location },
        14
      );
    });
  };

  const onSearch = async (text) => {
    if (text !== "") {
      autocompleteService.autocomplete(
        { input: text, components: { country: ["it"] } },
        (response) => {
          if (response.localities.length <= 0) {
            setNotFound(true);
          }
          setResults(response.localities);
        },
        (errorCode, errorText) => {
          console.error(`error ${errorCode} : ${errorText}`);
        }
      );
    } else {
      setNotFound(false);
      setResults([]);
    }
  };

  const renderResults = results.map(({ description, public_id }, index) => {
    return (
      <PredictionPreview
        key={index}
        searchNearbyStores={searchNearbyStores}
        index={index}
        description={description}
        id={public_id}
      />
    );
  });

  return (
    <>
      <div className="control  has-icons-right">
        <input
          className={`input is-medium ${
            keyword.length > 0 ? "autocomplete-input" : ""
          }`}
          type="email"
          placeholder="Ricerca ..."
          value={keyword}
          onChange={(e) => updateKeyword(e.target.value)}
        />

        <span
          className={`icon is-small is-right ${
            keyword.length > 0 ? "icon-search-active" : ""
          }`}
        >
          <FontAwesomeIcon icon={faSearch} />
        </span>
        <span
          className={`icon is-small is-right ${
            keyword.length > 0 ? "" : "icon-close-inactive"
          }`}
          onClick={() => updateText("")}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>

      {/* <div className="search">
            <button
                onClick={() => updateText("")}
                className={`autocomplete__cancelBtn ${keyword.length > 0 ? "active" : "inactive"}`}>
                <img src="https://images.woosmap.com/close.svg" alt="reset"/>
            </button>
            <button
                className={`autocomplete__searchIcon ${keyword.length > 0 ? "active" : "inactive"}`}>
                <img src="https://img.icons8.com/material-outlined/10/000000/search--v2.svg" alt="search"/>
            </button>
            <input
                className="autocomplete__input"
                placeholder="Ricerca ..."
                value={keyword}
                onChange={e => updateKeyword(e.target.value)}
            />
            </div> */}

      {results.length > 0 ? (
        <div className="predictionsResults">{renderResults}</div>
      ) : (
        notFound && (
          <div className="predictionsResults">{<ShowNoResultFound />} </div>
        )
      )}
    </>
  );
};

const ShowNoResultFound = () => {
  return (
    <div className="predictionPreview">
      <div className="predictionPreview__locality">
        <p className="predictionPreview__localityDesc">
          Nessun risultato per questa ricerca
        </p>
      </div>
    </div>
  );
};

const PredictionPreview = ({ description, index, searchNearbyStores, id }) => {
  return (
    <div
      onClick={() => searchNearbyStores(description, id)}
      className="predictionPreview"
    >
      <div className="predictionPreview__locality">
        <p className="predictionPreview__localityDesc">{description}</p>
      </div>
    </div>
  );
};

export default Autocomplete;
