import "./StorePreview.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
const getDistance = (distance) => {
  distance = Math.floor(distance);
  if (distance >= 1000) {
    return `${(distance / 1000).toFixed(2)} km`;
  }
  return `${distance} m`;
};
export default function StorePreview({ name, address, distance }) {
  return (
    <>
      <div className="column is-two-thirds">
        <div className="storePreview">
          <div className="storePreview__storeName">{name}</div>
          {address && (
            <>
              <div className="storePreview__storeAddressLines">
                {address.lines}
              </div>
              <div className="storePreview__storeAddressLines">
                {address.zipcode} {address.city} {address.country_code}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="column is-one-third">
          <div className="storePreview__storeDistance--icon">
               <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
           <div className="storePreview__storeDistance--distance">
               
                {
                    getDistance(distance)
                }
            </div>
      </div>
      {/* <div className="storePreview">
            <div className="storePreview__storeName">
                {name}
            </div>
            {address &&
            
            <div className="storePreview__storeAddressLines">
                {address.lines}
            </div>
            <div className="storePreview__storeAddressLines">
                {address.zipCode} {address.city} {address.country_code}
            </div>
            </>
            }
           
        </div> */}
    </>
  );
}
