import "./Home.css";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      
       <div className="hovering-image">
        <img src="assets/pin_home.png" />
    </div>
      <div className="columns is-multiline" style={{ height: "100%" }}>
        <div
          className="column is-full"
          style={{ background: "#e8e8e8", height: "50%" }}
        >
          <div className="columns  is-vcentered" style={{ height: "100%", width:'60%', margin:'auto' }}>
            <div className="column">
              <div >
                <p className="home-column-text">
                  Sei un utente finale,
                  <br /> trova il punto vendita <br />
                  KYOCERA <br /> piu vicino a te
                </p>
              </div>
            </div>
            <div className="column">
              <div >
                <div className="home-accessMap">
                  <Link to="/map?type=user">Accedi alla mappa</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="column is-full"
          style={{ height: "50%", background: "#ffffff" }}
        >
          <div
            className="columns is-centered is-vcentered"
            style={{ height: "100%" , width:'60%', margin:'auto' }}
          >
            <div className="column">
              <div >
                <p className="home-column-text">
                  Sei un rivenditore,
                  <br /> trova il distributore <br />
                  KYOCERA <br /> più vicino a te
                </p>
              </div>
            </div>
            <div className="column">
              <div >
                <div className="home-accessMap">
                  <Link to="/map?type=reseller">Accedi alla mappa</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
