import StorePreview from "../components/StorePreview";
import "./StoresList.css";
import { useEffect, useState } from "react";
import conf from "../config.json";
import { getCategories } from "../Map";
import { useLocation } from "react-router-dom";

export default function StoresList({
  location,
  mapView,
  dataSource,
  selectedCategories,
  selectedTags,
  setSelectedStore,
}) {
  const type = new URLSearchParams(useLocation().search).get("type");

  const [stores, setStores] = useState([]);
  const [results, setResults] = useState(true);
  useEffect(() => {
    search(location, selectedCategories, selectedTags);
  }, [location, selectedCategories, selectedTags]);

  const selectStore = (store) => {
    mapView.set("selectedStore", store);
    setSelectedStore(store);
  };

  const categoryFilter = (categories) => {
    let q = window.woosmap.query;

    const selectedCategoriesQuery = categories.reduce((acc, category) => {
      acc.push(q.F("type", category));
      return acc;
    }, []);
    if (selectedCategories.length <= 0) {
      return getCategories(type);
    }
    // query = q.or([...selectedCategoriesQuery]);
    return selectedCategoriesQuery;
  };

  const tagsFilter = (tags) => {
    let q = window.woosmap.query;
    const selectedTagsQuery = tags.reduce((acc, tag) => {
      acc.push(q.F("tag", tag));
      return acc;
    }, []);
    // query = q.or([...selectedCategoriesQuery]);
    return selectedTagsQuery;
  };

  const search = (location, selectedCategories, selectedTags) => {
    if (location) {
      //Get Categories Filter
      const selectedCategoriesQuery = categoryFilter(selectedCategories);
      const selectedTagsQuery = tagsFilter(selectedTags);
      let q = window.woosmap.query;
      let query;
      if (selectedCategoriesQuery.length > 0 && selectedTagsQuery.length > 0) {
        query = q.and([
          q.or([...selectedCategoriesQuery]),
          q.or([...selectedTagsQuery]),
        ]);
      } else if (
        selectedCategoriesQuery.length <= 0 &&
        selectedTagsQuery.length > 0
      ) {
        query = q.or([...selectedTagsQuery]);
      } else if (
        selectedCategoriesQuery.length > 0 &&
        selectedTagsQuery.length <= 0
      ) {
        query = q.or([...selectedCategoriesQuery]);
      }
      //   const query = q.and([...selectedCategoriesQuery, ...selectedTagsQuery]);
      const searchParams = new window.woosmap.search.SearchParameters({
        lat: location.lat,
        lng: location.lng,
        page: 1,
        storesByPage: conf.storesByPage,
        query:
          selectedCategoriesQuery.length > 0 || selectedTagsQuery.length > 0
            ? query
            : "",
      });
      dataSource.searchStoresByParameters(searchParams, (nearbyStores) => {
        setStores(nearbyStores.features);
        if (nearbyStores.features && nearbyStores.features.length <= 0) {
          setResults(false);
        }
        // setSelectedStore(nearbyStores.features[0])
      });
    } else {
    
      setStores([]);
    }
  };

  return  stores.length > 0 ? (
    <section className="storesList">
      {stores.map((store) => (
        <div
          className="columns container is-vcentered is-centered"
          key={store.properties.store_id}
          onClick={() => selectStore(store)}
        >
          <StorePreview
            name={store.properties.name}
            address={store.properties.address}
            distance={store.properties.distance}
          />
        </div>
      ))}
    </section>
  ) : (
     (stores.length <= 0 && !results) && <NoResult />
  );
}

const NoResult = () => {
  return (
    <section className="storesList">
      <div className="columns container is-vcentered is-centered">
        <p> Nessun risultato per questa ricerca</p>
      </div>
    </section>
  );
};
