import "./Sidebar.css";
import Autocomplete from "./Autocomplete";
import AroundMe from "./Aroundme";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faPhoneAlt,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import StoresList from "./StoresList";
import React, { useEffect } from "react";
import { useState } from "react";
import Filters from "./Filters";
import conf from "../config.json";
import { getCategories } from "../Map";
export default function Sidebar({
  mapView,
  dataSource,
  categories,
  tags,
  map,
  selectedStore,
  setSelectedStore,
}) {
  const type = new URLSearchParams(useLocation().search).get("type");
  const [keyword, setKeyword] = useState("");

  const [location, setLocation] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [geoLocate, setGeoLocate] = useState(false);
  const [marker, setMarker] = useState(null);
  const [radius, setRadius] = useState(null);
  const selectCategories = (category) => {
    let newCategories = [...selectedCategories];
    if (newCategories.includes(category)) {
      newCategories = newCategories.filter(
        (newCategory) => newCategory !== category
      );
    } else {
      newCategories = [...selectedCategories, category];
    }
    setSelectedCategories(newCategories);
    startFilter({ categories: newCategories, tags: selectedTags });
  };

  const selectTags = (tag) => {
    let newTags = [...selectedTags];
    if (selectedTags.includes(tag)) {
      newTags = newTags.filter((newTag) => newTag !== tag);
    } else {
      newTags = [...selectedTags, tag];
    }
    setSelectedTags(newTags);
    startFilter({ tags: newTags, categories: selectedCategories });
  };

  const startFilter = ({ categories, tags }) => {
    let query,
      q = window.woosmap.query;
    let selectedCategoriesQuery = [];
    let selectedTagsQuery = [];
    if (categories) {
      selectedCategoriesQuery = categories.reduce((acc, category) => {
        acc.push(q.F("type", category));
        return acc;
      }, []);
    }
    if (tags) {
      selectedTagsQuery = tags.reduce((acc, tag) => {
        acc.push(q.F("tag", tag));
        return acc;
      }, []);
    }

    if (selectedCategoriesQuery.length > 0 && selectedTagsQuery.length > 0) {
      query = q.and([
        q.or([...selectedCategoriesQuery]),
        q.or([...selectedTagsQuery]),
      ]);
    } else if (
      selectedCategoriesQuery.length <= 0 &&
      selectedTagsQuery.length > 0
    ) {
      query = q.and([
        q.or(...getCategories(type)),
        q.or([...selectedTagsQuery]),
      ]);
    } else if (
      selectedCategoriesQuery.length > 0 &&
      selectedTagsQuery.length <= 0
    ) {
      query = q.or([...selectedCategoriesQuery]);
    } else {
      query = q.and([q.or(...getCategories(type))]);
    }

    const searchParams = new window.woosmap.search.SearchParameters({
      query,
    });
    mapView.setSearchParameters(searchParams);
  };

  const showOtherFields = () => {
    return (
      <>
        <div className="row">
          <AroundMe
            locationHooks={location}
            setLocationHooks={setLocation}
            map={map}
            geoLocate={geoLocate}
            marker={marker}
            radius={radius}
            setGeoLocate={setGeoLocate}
            setMarker={setMarker}
            setRadius={setRadius}
            keyword={keyword}
            setKeyword={setKeyword}
          />
        </div>
        <div className="row">
          <Filters
            locationHooks={location}
            categories={categories}
            tags={tags}
            selectedCategories={selectedCategories}
            selectCategories={(category) => selectCategories(category)}
            selectedTags={selectedTags}
            selectTags={(tag) => selectTags(tag)}
          />
        </div>
        <StoresList
          location={location}
          mapView={mapView}
          dataSource={dataSource}
          selectedCategories={selectedCategories}
          selectedTags={selectedTags}
          setSelectedStore={setSelectedStore}
        />
      </>
    );
  };

  const openWebsite = () => {
    let link = selectedStore.properties.contact.website;
    link = link.replace(/(^\w+:|^)\/\//, "");
    const newWindow = window.open(`//${link}`, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const goback = () => {
    setSelectedStore(null);
    mapView.set("selectedStore", null);
  };

  const showSelectedStore = () => {
    return (
      <article className="panel">
        <div className="panel-heading selectedStore">
          <p>
            <span className="selectedStore_title is-small" onClick={goback}>
              <FontAwesomeIcon icon={faLongArrowAltLeft} />
            </span>
            <span className="selectedStore_title is-small">
              {selectedStore.properties.name}
            </span>
          </p>
        </div>
        <div className="panel-block selectedStore__details">
          {selectedStore.properties.address.city && (
            <>
              <div className="selectedStore__storeAddressLines">
                {selectedStore.properties.address.lines}
              </div>
              <div className="selectedStore__storeAddressLines">
                {selectedStore.properties.address.zipcode}{" "}
                {selectedStore.properties.address.city}{" "}
                {selectedStore.properties.address.country_code}
              </div>
            </>
          )}

          {selectedStore.properties.contact && (
            <div className="selectedStore__contact">
              {selectedStore.properties.contact.phone && (
                <div className="selectedStore__contact--phone">
                  <span className="selectedStore__contact--phone-icon is-small">
                    <FontAwesomeIcon icon={faPhoneAlt} />
                  </span>
                  {selectedStore.properties.contact.phone}
                </div>
              )}
              {selectedStore.properties.user_properties &&
                selectedStore.properties.user_properties.fax &&
                selectedStore.properties.user_properties.fax !== "" && (
                  <div className="selectedStore__contact--fax">
                    <span className="selectedStore__contact--fax-icon is-small">
                      <FontAwesomeIcon icon={faFax} />
                    </span>
                    {selectedStore.properties.user_properties.fax}
                  </div>
                )}

              {selectedStore.properties.contact.website && (
                <div
                  className="selectedStore__contact--website"
                  onClick={openWebsite}
                >
                  {selectedStore.properties.contact.website}
                </div>
              )}

              {selectedStore.properties.contact.email && (
                <div className="selectedStore__contact--email">
                  {selectedStore.properties.contact.email}
                </div>
              )}
            </div>
          )}
          <p className="selectedStore__categories--title">Categorie</p>

          <div className={`selectedStore__categories--logo-container `}>
            <div
              className={`selectedStore__categories--logo-container-logo selectedStore__categories--logo-container-logo--${selectedStore.properties.types[0].replace(
                / /g,
                "-"
              )}`}
            >
              <img
                src={`assets/images/${selectedStore.properties.types[0].replace(
                  /-/g,
                  " "
                )}-OFF.png`}
              />
            </div>
          </div>

          <p className="selectedStore__certificates--title">Certificazioni</p>
          <div className="selectedStore__certificates--certificate">
            {selectedStore.properties.tags.length > 0 ? (
              selectedStore.properties.tags.map((tag) => (
                <p className="selectedStore__certificates--name">{tag}</p>
              ))
            ) : (
              <p className="selectedStore__certificates--name">
                Nessuna certificazione presente
              </p>
            )}
          </div>
        </div>
      </article>
    );
  };

  return (
    <div className="sidebar">
      <div className="row">
        <Autocomplete
          locationHooks={location}
          setLocationHooks={setLocation}
          map={map}
          mapView={mapView}
          keyword={keyword}
          setKeyword={setKeyword}
          geoLocate={geoLocate}
          setGeoLocate={setGeoLocate}
        />
      </div>
      {selectedStore ? showSelectedStore() : showOtherFields()}
    </div>
  );
}
