import React, { useRef, useEffect, useState, createFactory } from "react";
import ReactDOMServer from "react-dom/server";
import conf from "./config.json";
import useScript from "./hooks/useScript";
import Sidebar from "./components/Sidebar";
import InfoWindow from "./components/InfoWindow.js";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./Map.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export const Categories = {
  user: ["KDIT KEP", "KDIT Platinum", "KDIT Gold", "KDIT Silver"],
  reseller: ["KDIT HW Distributor", "KDIT Supply Distributor"],
};

const Categories_map = {
  user: {
    "KDIT KEP": "Excellence Point",
    "KDIT Platinum": "Platinum",
    "KDIT Gold": "Gold",
    "KDIT Silver": "Silver",
  },
  reseller: {
    "KDIT HW Distributor": "Distributore Hardware",
    "KDIT Supply Distributor": "Distributore Consumabili",
  },
};
export const Categories_COLOR = {
  "KDIT Silver": "#585858",
  "KDIT KEP": "#F06400",
  "KDIT Platinum": "#6E3CBE",
  "KDIT Gold": "#F5B400",
  "KDIT HW Distributor": "#0A9BCD",
  "KDIT Supply Distributor": "#282828",
};

export const getCategories = (type, initial = []) => {
  let q = window.woosmap.query;
  return Categories[type].reduce((acc, category) => {
    acc.push(q.F("type", category));
    return acc;
  }, initial);
};

const Map = () => {
  const type = new URLSearchParams(useLocation().search).get("type");
  const mapContainerRef = useRef(null);
  const [showLegend, setShowLegend] = useState(null);
  const [mapRef, setMapRef] = useState(null);
  const [mapView, setMapView] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const woosmapLoaded = useScript(conf.woosmapLoaderUrl);
  const [category, setCategory] = useState(null);
  const [tags, setTags] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  useEffect(() => {
    if (woosmapLoaded) {
      initMap();
    }
  }, [woosmapLoaded]);

  const initMap = () => {
    conf.woosmapLoadOptions.callback = () => {
      const loader = new window.woosmap.MapsLoader(conf.googleLoadOptions);
      loader.load(() => {
        const map = new window.google.maps.Map(
          mapContainerRef.current,
          conf.googleMapsOptions
        );
        setMapRef(map);
        const dataSource = new window.woosmap.DataSource();

        const mapView = new window.woosmap.TiledView(
          map,
          conf.woosmapStyleOptions
        );

        const queries = getCategories(type);
        let q = window.woosmap.query;
        let query = q.or([...queries]);
        const initalSearchParams = new window.woosmap.search.SearchParameters({
          query,
        });
        mapView.setSearchParameters(initalSearchParams);

        const searchParams = new woosmap.search.SearchParameters({
          lat: conf.googleMapsOptions.center.lat,
          lng: conf.googleMapsOptions.center.lng,
          page: 1,
          storesByPage: conf.storesByPage,
          query,
        });
        mapView.addListener("click", (store) => {
          mapView.set("selectedStore", store);
          new window.woosmap.maps.utils.centerAndZoom(
            map,
            {
              lat: store.geometry.coordinates[1],
              lng: store.geometry.coordinates[0],
            },
            9
          );
          setSelectedStore(store);
        });

        dataSource.searchStoresByParameters(searchParams, (stores) => {
          const features = stores.features;
          let categories = [],
            tags = [];
          features.forEach((feature) => {
            if (feature.properties.types[0] !== "")
              categories.push(feature.properties.types[0]);
            if (feature.properties.tags.length > 0) {
              feature.properties.tags.map((tag) => {
                tags.push(tag);
              });
            }
          });
          categories = Array.from(new Set(categories));
          tags = Array.from(new Set(tags));
          setCategory(categories);
          setTags(tags);
          setLoaded(true);
        });

        setMapView(mapView);
        setDataSource(dataSource);
      });
    };
    window.WoosmapLoader.load(conf.woosmapLoadOptions);
  };

  return (
    <div style={{ position: "relative", height: "calc(100vh - 80px)" }}>
      <div className="mapContainer" ref={mapContainerRef} />
      {loaded && (
        <Sidebar
          mapView={mapView}
          dataSource={dataSource}
          categories={category}
          tags={tags}
          map={mapRef}
          selectedStore={selectedStore}
          setSelectedStore={setSelectedStore}
        />
      )}

      <div className="floating-items">
        <div className="legend" onClick={() => setShowLegend(!showLegend)}>
          <p className="legend-text">Legenda</p>
          <div className="legend-icon-container">
            <img
              src="assets/images/ICO_Legenda.png"
              style={{ width: "40%", cursor: "pointer" }}
            ></img>
          </div>
        </div>
        <div className="back">
          <p className="legend-text">Cambia Mappa</p>
          <Link to="/">
            <div className="legend-icon-container">
              <img
                src="assets/images/ICO_Cambia_Mappa.png"
                style={{ width: "80%", cursor: "pointer" }}
              ></img>
            </div>
          </Link>
        </div>
        {showLegend && (
          <div className="legend-display">
            {Categories[type].map((cat) => {
              return (
                <p className="legend-display-items">
                  <img src={`assets/images/${cat}.png`} />
                  {Categories_map[type][cat]}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Map;
