import './Header.css'
const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="columns is-desktop is-vcentered is-hidden-mobile"style={{marginBottom: 'calc(1rem - 0.75rem)'}}>

        <div className="column is-half" style={{position:'relative'}}>
            <div style={{display:'flex', alignItems: 'center'}}>
             <img src="assets/logo.png" style={{cursor:'pointer', width: 'calc(50% - 80px)'}} onClick={() => window.open('https://www.kyoceradocumentsolutions.it/it.html')}></img>
             <p style={{fontSize:'2vh', fontWeight: '500'}}>Kyocera Document <br />Solutions</p>
             </div>
        </div>
        <div className="column is-half is-hidden-desktop-only" style={{textAlign:'right'}}>
             <p className="header-title">Trova dove acquistare i <span className="header-title header-title--red">prodotti</span> KYOCERA</p>
        </div>
      </div>

      <div className="columns is-mobile is-hidden-desktop is-hidden-tablet">

        <div className="column" style={{position:'relative'}}>
            <div style={{display:'flex', alignItems: 'center'}}>
             <img src="assets/logo.png" style={{cursor:'pointer', width: '40%'}} onClick={() => window.open('https://www.kyoceradocumentsolutions.it/it.html')}></img>
             <p style={{fontSize:'10px', fontWeight: '500'}}>Kyocera Document <br />Solutions</p>
             </div>
        </div>
        
      </div>
      </div>
    </header>
  );
};

export default Header;
